<template>
    <!-- TODO: N/A if Confidential-->
    <div class="row row-container" :class="$attrs.class">
        <div class="col-12 row-container">
            <div class="row">
                <div class="col-12">
                    <OOrgUnitsLookup :bind="addOrgUnit" noClear>
                        <template #orgunit="{ target }">
                            <h6 :ref="target" class="d-inline text-primary" style="cursor:pointer" :title="$t('Grants access to the org unit once the document is released (users in the org unit must also be member of role with access to the document group).')">
                            <i class="bi bi-plus-lg"></i> {{$t('Distribute To Org Unit')}}</h6>
                        </template>
                    </OOrgUnitsLookup>

                    <label class="form-check-label d-inline me-1 pt-1 float-end">
                        <input class="form-check-input" type="checkbox" value="" @change="toggleOrgUnitsFromGrps">
                        {{$t('Excl. Org units from Distr. groups')}}
                    </label>
                </div>
            </div>
            <o-data-grid :data-object="dsDocOrgUnitsAll" hide-grid-menu :hideMenuItems="['recordinfo']" v-if="!vExcludeOrgUnitsFromGroups" style="max-height:400px;">
                <o-column field="OrgUnit" width="475" sortable :headerName="$t('Org Unit')" v-slot="{row}">
                    <div class="d-flex">
                        <div class="text-truncate">
                            <span>{{ row.OrgUnit }}</span> 
                                <span v-if="row.ImmediateAccess">
                                    (<i>{{ $t('Immediate Access') }}</i>
                                    <span v-if="row.AllowEdit">, </span>
                                    <i v-if="row.AllowEdit">{{ $t('Allow Edit') }}</i>)
                                </span>
                        </div>
                        <div class="ms-auto">
                            <i v-if="!row.DistributionSource" class="bi bi-gear me-2" style="cursor:pointer;" :title="$t('Additional Sharing Options')" @click="showAdvancedSettingsModal(row)" ></i>
                            <i class="bi bi-bell" style="cursor:pointer;" :title="$t('Inform Org Unit')" @click="distributeToOrgUnit(row.OrgUnit_ID,row.OrgUnit)"></i>
                        </div>
                    </div>
                </o-column>
                <o-column field="DistributionSource" width="200" :headerName="$t('Distribution Source')" :headerTitle="$t('Shows from which distribution group(s) the org unit has been added from (if any)')"></o-column>
                <o-column field="PreventCopyToNewRevision" width="225" :editable="row => row.DocOrgUnitRow" :cellTitle="row => !row.DocOrgUnitRow ? $t('N/A') : ''" :headerName="$t('Prevent Copy To New Revision')"></o-column>
                <o-column field="Purpose" width="200" hide :editable="row => row.DocOrgUnitRow && row.PurposeEditable" :cellTitle="row => !row.DocOrgUnitRow || !row.PurposeEditable ? $t('Cannot override purpose from distribution group') : ''" :headerName="$t('Purpose')"></o-column>
            </o-data-grid>
            <o-data-grid :data-object="dsDocOrgUnitsDocOnly" hide-grid-menu v-else-if="vExcludeOrgUnitsFromGroups" style="max-height:400px;">
                <o-column field="OrgUnit" width="475" sortable :headerName="$t('Org Unit')" v-slot="{row}">
                    <div class="d-flex">
                        <div class="text-truncate">
                            <span>{{ row.OrgUnit }}</span> 
                            <span v-if="row.ImmediateAccess">
                                (<i>{{ $t('Immediate Access') }}</i>
                                <span v-if="row.AllowEdit">, </span>
                                <i v-if="row.AllowEdit">{{ $t('Allow Edit') }}</i>)
                            </span>
                        </div>
                        <div class="ms-auto">
                            <i class="bi bi-gear me-2" style="cursor:pointer;" :title="$t('Additional Sharing Options')" @click="showAdvancedSettingsModal()" ></i>
                            <i class="bi bi-bell" style="cursor:pointer;" :title="$t('Inform Org Unit')" @click="distributeToOrgUnit(row.OrgUnit_ID,row.OrgUnit)"></i>
                        </div>
                    </div>
                </o-column>
                <o-column field="PreventCopyToNewRevision" width="225" editable bulkUpdate :headerName="$t('Prevent Copy To New Revision')"></o-column>
                <o-column field="Purpose" width="200" hide editable bulkUpdate :headerName="$t('Purpose')"></o-column>
            </o-data-grid>
        </div>
    </div>


    <OModal ref="advancedSettingsModalRef">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <h5 class="modal-header">
                    <span>{{ $t('Additional Sharing Options') }}</span>
                </h5>
                <div class="modal-body">
                    <div v-if="!vExcludeOrgUnitsFromGroups">
                        <span>{{ dsDocOrgUnitsAll.current.OrgUnit }}</span>
                    
                        <div class="form-check form-switch mt-3" 
                            :title="!(dsDocOrgUnitsAll.current.DocOrgUnitRow && dsDocOrgUnitsAll.current.ImmediateAccessEditable) ? $t('Cannot alter setting from distribution group') : $t('Grant access to the document before it is released')">
                            <input class="form-check-input" type="checkbox" v-model="immediateAccessTemp"  @change="() => {allowEditTemp = (!immediateAccessTemp ? false : allowEditTemp)}"
                                :disabled="!(dsDocOrgUnitsAll.current.DocOrgUnitRow && dsDocOrgUnitsAll.current.ImmediateAccessEditable)">
                            <label class="form-check-label" for="immediateAccess">{{$t('Immediate Access')}}</label>&nbsp;
                        </div>

                        <div class="form-check form-switch mt-1" 
                            :title="!immediateAccessTemp ? $t('Only applicable when Immediate Access has been enabled') : (!(dsDocOrgUnitsAll.current.DocOrgUnitRow && dsDocOrgUnitsAll.current.AllowEditEditable) ? $t('Cannot alter setting from distribution group') : '')">
                            <input class="form-check-input" type="checkbox" v-model="allowEditTemp"
                                :disabled="!immediateAccessTemp || !(dsDocOrgUnitsAll.current.DocOrgUnitRow && dsDocOrgUnitsAll.current.AllowEditEditable)">
                            <label class="form-check-label" for="allowEdit">{{$t('Allow Edit')}}</label>&nbsp;
                        </div>
                    </div>
                    <div v-else>
                        <span>{{ dsDocOrgUnitsDocOnly.current.OrgUnit }}</span>
                    
                        <div class="form-check form-switch mt-3" :title="$t('Grant access to the document before it is released')">
                            <input class="form-check-input" type="checkbox" v-model="dsDocOrgUnitsDocOnly.current.ImmediateAccess"  
                                @change="() => {dsDocOrgUnitsDocOnly.current.AllowEdit = (!dsDocOrgUnitsDocOnly.current.ImmediateAccess ? false : dsDocOrgUnitsDocOnly.current.AllowEdit)}">
                            <label class="form-check-label" for="immediateAccess">{{$t('Immediate Access')}}</label>&nbsp;
                        </div>

                        <div class="form-check form-switch mt-1" :title="!dsDocOrgUnitsDocOnly.current.ImmediateAccess ? $t('Only applicable when Immediate Access has been enabled') : ''">
                            <input class="form-check-input" type="checkbox" :disabled="!dsDocOrgUnitsDocOnly.current.ImmediateAccess" v-model="dsDocOrgUnitsDocOnly.current.AllowEdit">
                            <label class="form-check-label" for="allowEdit">{{$t('Allow Edit')}}</label>&nbsp;
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-top-0">
                    <button class="btn btn-outline-primary me-1" data-bs-dismiss="modal" @click="advancedSettingsModalSave()">{{$t('Save')}}</button>
                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="advancedSettingsModalCancel()">{{$t('Cancel')}}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject'
    import { userSession,getOrCreateProcedure } from 'o365-modules'
    import { OOrgUnitsLookup } from 'o365-system-lookups';
    import { alert } from 'o365-vue-services';
    import { confirm } from 'o365-vue-services'
    import { useDataObjectEventListener } from 'o365-vue-utils';
    
    const props = defineProps({
        dataObject: Object
    })

    const advancedSettingsModalRef = ref(null);
    const vExcludeOrgUnitsFromGroups = ref(false);
    const allowEditTemp = ref(false);
    const immediateAccessTemp = ref(false);

    const toggleOrgUnitsFromGrps = () => {
        vExcludeOrgUnitsFromGroups.value = !vExcludeOrgUnitsFromGroups.value;
        refreshGrid();
    }

    const dsDocOrgUnitsAllDefinition = {
        id: 'dsDocOrgUnitsAll',
        viewName: 'aviw_Arena_DocumentsOrgUnitsAgg',
        appId: props.dataObject.appId,
        uniqueTable:"atbv_Arena_DocumentsOrgUnits",
        allowDelete: true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        disableAutoLoad: false,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "PrimKey", type: "string" },
                {name: "Document_ID", type: "number" },
                {name: "OrgUnit_ID", type: "number"},
                {name: "OrgUnit", type: "string", sortOrder: 1, sortDirection: "asc"},
                {name: "DistributionSource", type: "string"},
                {name: "PreventCopyToNewRevision", type: "bit"},
                {name: "DocOrgUnitRow", type: "bit"},
                {name: "Purpose", type: "string"},
                {name: "PurposeEditable", type: "bit"},
                {name: "AllowEdit", type: "bit"},
                {name: "AllowEditEditable", type: "bit"},
                {name: "ImmediateAccess", type: "bit"},
                {name: "ImmediateAccessEditable", type: "bit"}
            ]
    }, dsDocOrgUnitsDocOnlyDefinition = {
        id: 'dsDocOrgUnitsDocOnly',
        viewName: 'aviw_Arena_DocumentsOrgUnits',
        appId: props.dataObject.appId,
        uniqueTable:"atbv_Arena_DocumentsOrgUnits",
        allowDelete: true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        disableAutoLoad: false,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "Document_ID", type: "number" },
                {name: "OrgUnit_ID", type: "number"},
                {name: "OrgUnit", type: "string", sortOrder: 1, sortDirection: "asc"},
                {name: "PreventCopyToNewRevision", type: "bit"},
                {name: "Purpose", type: "string"},
                {name: "AllowEdit", type: "bit"},
                {name: "ImmediateAccess", type: "bit"},
            ]
    }
    const dsDocOrgUnitsAll = getOrCreateDataObject(dsDocOrgUnitsAllDefinition);
    const dsDocOrgUnitsDocOnly = getOrCreateDataObject(dsDocOrgUnitsDocOnlyDefinition);

    const procInsertLkpSelection = getOrCreateProcedure({
        id: 'procInsertLkpSelection',
        procedureName: 'astp_Arena_DocumentsInsertLookupSelection'
    });
    const procDocOuSetPurpose = getOrCreateProcedure({
        id: 'procDocOuSetPurpose',
        procedureName: 'astp_Arena_DocumentsOrgUnitsSetPurpose'
    });
    const procNotifyOrgUnit = getOrCreateProcedure({
        id: 'procNotifyOrgUnit',
        procedureName: 'astp_Arena_NotifyOrgUnit'
    });
    const procSetPreventCopy = getOrCreateProcedure({
        id: 'procSetPreventCopy',
        procedureName: 'astp_Arena_DocumentsOrgUnitsPreventCopyToNewRev'
    });
    const procDocOrgUnitsSetShOpt = getOrCreateProcedure({
        id: 'procDocOrgUnitsSetShOpt',
        procedureName: 'astp_Arena_DocumentsOrgUnitsSetSharingOptions'
    });

    function addOrgUnit(row){
        procInsertLkpSelection.execute({
            Document_ID: props.dataObject.current.ID,
            Ids: [[row.ID]],
            Type: "OrgUnits"
        }).then((res)=>{
            refreshGrid();
        });
    }

    const advancedSettingsModalSave = () =>{
        if (!vExcludeOrgUnitsFromGroups.value){
            procDocOrgUnitsSetShOpt.execute({
                PrimKey: dsDocOrgUnitsAll.current.PrimKey,
                AllowEdit: allowEditTemp.value,
                ImmediateAccess: immediateAccessTemp.value
            }).then((res)=>{
                dsDocOrgUnitsAll.recordSource.refreshRow();
            });
        } else {
            dsDocOrgUnitsDocOnly.save();
        }
    }
    const advancedSettingsModalCancel = () =>{
        if (vExcludeOrgUnitsFromGroups.value){
            dsDocOrgUnitsDocOnly.cancelChanges();
        }
    }

    const showAdvancedSettingsModal = (pRow)=>{
        if (pRow){
            allowEditTemp.value = pRow.AllowEdit;
            immediateAccessTemp.value = pRow.ImmediateAccess;
        }

        advancedSettingsModalRef.value.show();
    }

    useDataObjectEventListener(dsDocOrgUnitsAll, 'BeforeUpdate', (options, item) => {
        options.cancelEvent = true;
    });

    useDataObjectEventListener(dsDocOrgUnitsAll, 'FieldChanged', (pField, pNewValue, pOldValue, pRow) => {
        if (pField == "PreventCopyToNewRevision"){
            procSetPreventCopy.execute({
                Document_ID: pRow.Document_ID,
                OrgUnit_ID: pRow.OrgUnit_ID,
                Value: pNewValue
            }).then((res)=>{
                dsDocOrgUnitsAll.recordSource.refreshRow(pRow.index);
            });
        } else if (pField == "Purpose"){
            docOrgUnitUpdatePurpose(pRow);
        }
    });

    useDataObjectEventListener(dsDocOrgUnitsDocOnly, 'FieldChanged', (pField, pNewValue, pOldValue, pRow) => {
        if (pField == "PreventCopyToNewRevision"){
            dsDocOrgUnitsDocOnly.save();
        }
    });

    var docOrgUnitsDebounce = {};
    function docOrgUnitUpdatePurpose(pRow){
        var vValue=event.target.value;
        if (docOrgUnitsDebounce.hasOwnProperty(pRow.PrimKey)){
            clearTimeout(docOrgUnitsDebounce[pRow.PrimKey]);
        }

        docOrgUnitsDebounce[pRow.PrimKey] = window.setTimeout(function () {
            procDocOuSetPurpose.execute({
                PrimKey: pRow.PrimKey,
                Value: vValue
            }).then((res)=>{
                dsDocOrgUnitsAll.recordSource.refreshRow(pRow.index);
            });
        }, 1000);
    }

    useDataObjectEventListener(dsDocOrgUnitsAll, 'BeforeDelete', (options, row) => {
        if (!row.DocOrgUnitRow){
            alert($t("Cannot delete org unit from distribution group"), "warning");
            options.cancelEvent = true;
        }
    });

    async function distributeToOrgUnit(pOrgUnit_ID, pOrgUnit){
        try {
            await confirm({
                title: $t('Inform Org Unit?'),
                message: $t("Are you sure you would like to inform") + ' ' + pOrgUnit + ' ' + $t("about this document profile? (Only notification subscribers will be informed)")
            });
            procNotifyOrgUnit.execute({
                Document_ID: props.dataObject.current.ID,
                OrgUnit_ID: pOrgUnit_ID
            }).then((res)=>{
                console.log(res);
                alert(res.Table[0].Message, "success", { autohide: true, delay: 3000 });
            });
        } catch (_) {}
    }

    function refreshGrid(){
        if (!vExcludeOrgUnitsFromGroups.value){
            dsDocOrgUnitsAll.load();
        } else {
            dsDocOrgUnitsDocOnly.load();
        }
    }

    onMounted(() => {
        refreshGrid();
    });

    defineExpose({ refreshGrid });
</script>


<style>
    .customInput:focus {
        background:transparent;
        background-color: rgb(var(--bs-body-bg-rgb)) !important;
    }
</style>